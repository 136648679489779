import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environment/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  
   constructor() {
    let obj = <IUser>{};
    obj.currentUser = window.sessionStorage.getItem('currentUser');
    // obj.userProfilePic = JSON.parse(obj?.currentUser)?.profile_pic ? environment.storageUrl + JSON.parse(obj.currentUser).profile_pic : '';
    this.setmode(obj);
  }

  private user = new BehaviorSubject<any>({} as any);
  user$ = this.user.asObservable();


  setmode(data: any) {
    if(data.userProfilePic && !data.userProfilePic?.startsWith('https:')){
      data.userProfilePic = environment.storageUrl + data.userProfilePic;
    }else{
      data.userProfilePic = JSON.parse(data?.currentUser)?.profile_pic  ? environment.storageUrl + JSON.parse(data?.currentUser).profile_pic  : '';
    }   
    this.user.next(data);
  }

  isNotNull(x: any): boolean {
    return !(x === undefined || x === null || x === "")
  }



  encrypt(text: string, skey: string) {
    
    if (this.isNotNull(text))
        return CryptoJS.AES.encrypt(text, skey.trim()).toString();
    else
        return null;
}

decrypt(text: any, skey: string) {
    if (this.isNotNull(text))
        return CryptoJS.AES.decrypt(text, skey.trim());
    else
        return null;
}

}

export interface IUser {
  currentUser: any;
  userProfilePic : any;
}
