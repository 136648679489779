import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss']
})
export class VideoViewerComponent implements OnInit{

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  constructor(private route: ActivatedRoute,private sanitizer: DomSanitizer) { }
  videoSource:any = null
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.videoSource = this.sanitizer.bypassSecurityTrustResourceUrl(params.url);
    })
  }

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }


}
