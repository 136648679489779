import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, debounceTime, fromEvent, merge } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { profile } from 'src/app/core/config/validation.config';
import { UserService, IUser } from 'src/app/core/services/user/user.service';
import { GenericValidator } from 'src/app/core/utils/directive/generic-validator';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environment/environment';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formControls!: ElementRef[];
  userProfilePic: any;
  profile_pic: any;
  private genericValidator: GenericValidator;
  private validationMessages: { [key: string]: { [key: string]: string } };
  displayMessage: { [key: string]: string } = {};
  user_id: any;
  currentUser: any
  profileFormGroup!: FormGroup;
  email: any;
  firstName:any;
  lastName:any;
  disableSaveBtn: boolean = false
  errMessage: string;
  @Output() sidenavClose= new EventEmitter();
  @ViewChild('myInput') myInput: ElementRef;
  profilePicUpdated: boolean = false;
  constructor(private _formBuilder: FormBuilder,
    private _uService:UserService,
    private toastr: ToastrService,
    private authservice: AuthService,
    private sharedService: SharedService,
    private router: Router, private authService:AuthService
  ) {
    this.getProfile();
    this.validationMessages = profile
    this.currentUser = window.sessionStorage.getItem('currentUser');
    this.genericValidator = new GenericValidator(this.validationMessages);
    this.userProfilePic = JSON.parse(this.currentUser).profile_pic  ? environment.storageUrl + JSON.parse(this.currentUser).profile_pic  : '';
    this.user_id = JSON.parse(this.currentUser).user_id;

  }

  ngOnInit(): void {
    this.profileFormGroup = this._formBuilder.group({
      sFirstName: ['', Validators.required],
      sLastName: ['', Validators.required]
    });

    JSON.parse(this.currentUser).first_name ? this.profileFormGroup.controls['sFirstName'].setValue(JSON.parse(this.currentUser).first_name) : ""
    JSON.parse(this.currentUser).last_name ? this.profileFormGroup.controls['sLastName'].setValue(JSON.parse(this.currentUser).last_name) : ""
    this.email = JSON.parse(this.currentUser).email;
    this.firstName = JSON.parse(this.currentUser).first_name;
    this.lastName = JSON.parse(this.currentUser).last_name;
  }
getProfile(){
  this.authService.getProfile("/auth/get_user_profile","").subscribe(data => {
    
    var curdata: any = window.sessionStorage.getItem('currentUser');
    curdata = curdata ? JSON.parse(curdata) : {};
    curdata['profile_pic'] = (data.user_details.profile_pic);
    sessionStorage.setItem('currentUser', JSON.stringify(curdata));
    this.userProfilePic = JSON.parse(this.currentUser).profile_pic  ? environment.storageUrl + JSON.parse(this.currentUser).profile_pic  : '';
    
  })
}
  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formControls
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));
    // Merge the blur event observable with the valueChanges observable
    merge(this.profileFormGroup.valueChanges, ...controlBlurs).pipe(
      debounceTime(600)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.profileFormGroup);
    });
    // this.myInput.nativeElement.blur();
  }

  uploadFile(event: any) {
    const files = event.target.files;
    if (files.length === 0) return;
    const mimeType = files[0].type;
    if (
      mimeType != 'image/jpeg' &&
      mimeType != 'image/png' &&
      mimeType != 'image/jpg' &&
      mimeType != 'image/gif' &&
      mimeType != 'image/webp'
    ) {
      this.errMessage = 'Only jpg/jpeg/gif and png files are allowed!';
      document.getElementById('error-message').style.visibility = 'visible';
      setTimeout(function() {
        document.getElementById('error-message').style.visibility = 'hidden';
      }, 5000);
      return;
    } else {
      const reader = new FileReader();
      this.profile_pic = files;
      reader.readAsDataURL(files[0]);
      reader.onload = _event => {
        this.userProfilePic = reader.result;
      };
      this.profilePicUpdated = true;
    }
  }
  saveProfile() {
    this.disableSaveBtn = true
    let fData;
    if (this.profile_pic) {
      fData = new FormData();
      fData.append('profile_file', this.profile_pic[0]);
    }
    let data = {
      user_id: this.user_id,
      fName: this.profileFormGroup.controls['sFirstName'].value,
      lName: this.profileFormGroup.controls['sLastName'].value,
      fData: fData ? fData : ''
    }
    let queryParam = '?first_name=' + data.fName + '&last_name=' + data.lName;
    this.authservice.updateProfile('/auth/update_user_profile', queryParam, data.fData)
      .subscribe(
        (data: any) => {
          this.disableSaveBtn = false
          if (data) {
            let datauser = {
              email: JSON.parse(this.currentUser).email,
              first_name: data.user_details.first_name,
              last_name: data.user_details.last_name,
              profile_pic: data.user_details.profile_pic,
              user_id: data.user_details.user_id,
              user_name: JSON.parse(this.currentUser).user_name,
              user_type: JSON.parse(this.currentUser).user_type,
            }
            window.sessionStorage.setItem('currentUser',JSON.stringify(datauser))
            this.toastr.success('Success !', 'Profile updated successfully.');
            let obj=<IUser>{};
            obj.currentUser = JSON.stringify(datauser),
            obj.userProfilePic = data.user_details.profile_pic ? environment.storageUrl + data.user_details.profile_pic : '';
            this._uService.setmode(obj);
            this.sidenavClose.emit(true);
          }
        }, err => {
          this.disableSaveBtn = false
          this.toastr.error('We are unable to process your request. Please try later', 'Warning !');
        }
      );
  }


  logout() {
    this.authservice.logout().subscribe((data:any) => {
      if (data) {
        sessionStorage.clear();
        this.authservice.currentUserSub(null);
        this.authservice.setTopicID(0);
        this.sharedService.changeProject('');
        this.sharedService.changeIndustry('');
        this.sharedService.changeBusiness('');
        this.sharedService.filterShareDashboard(null);
        this.router.navigate(['/']);
      }
    });
  }

}
