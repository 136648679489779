<div class="g-container">
  <div class="row justify-content-between align-items-center sticky">
    <div class="col">
      <img class="logo" src="assets/images/InnoBoon-Logo.png" alt="Logo">
    </div>
    <div class="col text-center">
      <div class="row">
        <div class="col-9">
          <div class="search-container">
            <input class="form-control me-2 custom-search-input" type="search" [(ngModel)]="searchTerm"
                   placeholder="Discover our Bots..." aria-label="Search">
            <mat-icon class="search-img" matSuffix *ngIf="!searchTerm">search</mat-icon>
          </div>
        </div>
        <div class="col-1 mx-1">
          <div class="filter" [matMenuTriggerFor]="menu" >
            <img src="assets/images/filter.svg">
          </div>
        </div>
      </div>
    </div>

    <mat-menu #menu="matMenu">
      <div class="filter-container p-4">
        <div class="row-auto my-2">
          <span class="filter-head">Projects </span>
          <ng-container matMenuTrigger>
            <div class="custom-dropdown mt-2" (click)="toggleDropdown('project', $event)">
              <div class="selected-item">
                {{ selectedItems.project || 'Select Project' }}
                <i class="arrow-icon fas" [ngClass]="{ 'fa-chevron-up': isDropdownOpen.project, 'fa-chevron-down': !isDropdownOpen.project }"></i>
              </div>
              <div class="dropdown-list" *ngIf="isDropdownOpen.project">
                <div class="dropdown-item" *ngFor="let option of projectTypes | keyvalue" (click)="onSelectChange('project', option)">{{ option.value }}</div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row-auto my-2">
          <span class="filter-head"> Industry</span>
          <ng-container matMenuTrigger>
            <div class="custom-dropdown mt-2" (click)="toggleDropdown('industry', $event)">
              <div class="selected-item">
                {{ selectedItems.industry || 'Select Industry' }}
                <i class="arrow-icon fas" [ngClass]="{ 'fa-chevron-up': isDropdownOpen.industry, 'fa-chevron-down': !isDropdownOpen.industry }"></i>
              </div>
              <div class="dropdown-list" *ngIf="isDropdownOpen.industry">
                <div class="dropdown-item" *ngFor="let option of industries | keyvalue" (click)="onSelectChange('industry', option)">{{ option.value }}</div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row-auto my-2">
          <span class="filter-head">Business</span>
          <ng-container matMenuTrigger>
            <div class="custom-dropdown mt-2" (click)="toggleDropdown('business', $event)">
              <div class="selected-item">
                {{ selectedItems.business || 'Select Business' }}
                <i class="arrow-icon fas" [ngClass]="{ 'fa-chevron-up': isDropdownOpen.business, 'fa-chevron-down': !isDropdownOpen.business }"></i>
              </div>
              <div class="dropdown-list" *ngIf="isDropdownOpen.business">
                <div class="dropdown-item" *ngFor="let option of businessList | keyvalue" (click)="onSelectChange('business', option)">{{ option.value }}</div>
              </div>
            </div>
          </ng-container>
        </div>
    
        <div class="row-auto text-end">
          <button type="button" class="btn clear-btn mx-2 mt-4"  (click)="onReset()">Clear</button>
          <button type="button" class="btn apply-btn mx-2 mt-4" (click)="applyFilters()">Apply</button>
        </div>
      </div>
    </mat-menu>

    
    <div class="col text-end">
      <div class="d-flex justify-content-end bg-primary text-white p-2 rounded" style="width: 100%;">
        <img [src]="userProfilePic || 'assets/images/user.png'" alt="Avatar" class="rounded-circle me-3" style="width: 40px; height: 40px;">
        <div>
          <div class="fw-bold">Hello, {{firstName}} {{lastName}}</div>
          <div style="font-size: 15px;">{{email}}</div>
        </div>
        <button style="margin-left: 10px; border: 1px solid #808080c2;" class="btn dropdown-toggle" type="button"
          (click)="onToggle(true)" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
        </button>
      </div>
    </div>

    <div class="container w-change" *ngIf="apiResponseData.length > 0">
      <div class="row">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg ">
            <nav class="navbar navbar-expand-lg ">
              <div class="container-fluid-1">
                <div class="navbar-nav">
                  <a class="nav-link heading-font" [class.active]="isActive(link.app_id)"
                    (click)="scrollTo(link.app_id)" *ngFor="let link of apiResponseData">{{ link.app_name }}</a>
                </div>
              </div>
            </nav>
          </nav>
        </div>
      </div>
    </div>

    <div class="no-content" *ngIf="!isLoading && apiResponseData.length == 0">
      <img src="assets/images/empty.svg">
      <label class="pt-4 mr-4">No results found</label>
    </div>
  </div>

  <div class="main-container mt-2" #mainContainer>
    <div *ngFor="let section of getFilteredResults()">
      <div *ngFor="let row of section.rows" class="row">
        <div *ngFor="let column of row.columns" [ngClass]="column.class">
          <div *ngFor="let card of column.cards" class="card mb-4" [style.height]="card.height" style="width: 100%;">
            <div [ngStyle]="card.bodyStyle" id="app-{{column.apps_id}}" class="row card-body feature-cards">
  
              <ng-container *ngIf="!card.imageUrl">
                <div class="col-12 cus-img" (click)="onRoute(card.redirectUrl, card.is_website)">
                  <img [src]="card.imageUrlmain" [ngClass]="card.cardBody" alt="Card Image">
                </div>
                <div class="col-12 cus-title" (click)="onRoute(card.redirectUrl, card.is_website)">
                  <div class="custom-card-title">
                    <h5 class="custom-card-head">{{ card.title }}</h5>
                    <p class="description px-3 text-start">{{ column.app_description }}</p>
                    <p class="special_description" *ngIf="card.special_description">{{ card.special_description }}</p>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="card.imageUrl">
                <div class="col-12 d-flex items-center justify-content-end p-2">
                  <img class="ppt new-icon" *ngIf="card.is_external_link" (click)="openPopup(card.id)" src="assets/dashboard_images/new/ppt.svg" />
                  <mat-icon class="v-ico new-icon" *ngIf="card.video_link != null" (click)="openVideo(card.video_link)">play_circle_outline</mat-icon>
                </div>
                <div class="col-12" (click)="onRoute(card.redirectUrl, card.is_website)">
                  <img [src]="card.imageUrl" [ngClass]="card.cardBody" alt="Card Image">
                </div>
                <div [ngClass]="card.footer" class="card-footer" (click)="onRoute(card.redirectUrl, card.is_website)">
                  <span class="card-footer-text">{{ card.title }}</span>
                  <p class="description">{{ card.description }}</p>
                  <ul class="detailed_description text-start">
                    <li *ngFor="let detail of card.detailed_description">{{ detail }}</li>
                  </ul>
                  <p class="special_description" *ngIf="card.special_description"><b>{{ card.special_description }}</b></p>
                  <span class="text-end d-block">
                    <img src="assets/dashboard_images/new/arrow.svg" class="arrow" />
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</div>
<div class="footer btm-sticky"> 
  <span > Copyright © 2021 Innoboon. All Rights Reserved.</span>
</div>

<mat-drawer-container class="example-container" *ngIf="sidenav">
  <mat-drawer #drawer (closed)="onToggle(false)" position="end" mode="over" [opened]="sideNavBar">
    <div class="side-navbar">
      <div class="pt-2 head" fxLayout="row" fxLayoutAlign="end center">
        <img src="/assets/images/Vector.svg" alt="Close" (click)="onToggle(false)" class="cursor-pointer">
      </div>
      <app-profile-info (sidenavClose)="onClose()"></app-profile-info>
    </div>
  </mat-drawer>
</mat-drawer-container> 



