import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {
  app_id: any
  images : any = []
	paused = false;
	unpauseOnArrow = false;
	pauseOnIndicator = false;
	pauseOnHover = true;
	pauseOnFocus = true;
	@ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  constructor(private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private dialog: MatDialog,) { }

 
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.app_id = params.url;
      if(this.app_id == 51){
        this.images = [
          "assets/coursel_images/neoSay/1.png",
          "assets/coursel_images/neoSay/2.png",
          "assets/coursel_images/neoSay/3.png",
          "assets/coursel_images/neoSay/4.png",
          "assets/coursel_images/neoSay/5.png",
          "assets/coursel_images/neoSay/5.png", 
        ];
      }else if(this.app_id == 53){
        this.images = [
          "assets/coursel_images/neoTag/1.png",
          "assets/coursel_images/neoTag/2.png",
        ];
      }else if(this.app_id == 55){
        this.images = [
          "assets/coursel_images/neoTrackLive/1.png",
          "assets/coursel_images/neoTrackLive/2.png",  
        ];
      }else if(this.app_id == 52){
        this.images = [
          "assets/coursel_images/neoWings/1.png",
          "assets/coursel_images/neoWings/2.png",
          "assets/coursel_images/neoWings/3.png",
          "assets/coursel_images/neoWings/4.png",
          "assets/coursel_images/neoWings/5.png",
          "assets/coursel_images/neoWings/6.png",
          "assets/coursel_images/neoWings/7.png", 
        ];
      }else if(this.app_id == 45){
        this.images = [
          "assets/coursel_images/retailHeatmap/1.jpg",
          "assets/coursel_images/retailHeatmap/2.jpg",
          "assets/coursel_images/retailHeatmap/3.jpg",
          "assets/coursel_images/retailHeatmap/4.jpg",
          "assets/coursel_images/retailHeatmap/5.jpg",
          "assets/coursel_images/retailHeatmap/6.jpg",
        ];
      }else if(this.app_id == 68){
        this.images = [
          "assets/coursel_images/travelAssist/1.png",
          "assets/coursel_images/travelAssist/2.png",
          "assets/coursel_images/travelAssist/3.png",
          "assets/coursel_images/travelAssist/4.png",
          "assets/coursel_images/travelAssist/5.png",
          "assets/coursel_images/travelAssist/6.png",
          "assets/coursel_images/travelAssist/7.png",
        ];
      }
    });

  }

  
	togglePaused() {
		if (this.paused) {
			this.carousel.cycle();
		} else {
			this.carousel.pause();
		}
		this.paused = !this.paused;
	}

	onSlide(slideEvent: NgbSlideEvent) {
		if (
			this.unpauseOnArrow &&
			slideEvent.paused &&
			(slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)
		) {
			this.togglePaused();
		}
		if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
			this.togglePaused();
		}
	}
}
