<section class="profile">
    <h2 class="mx-4"> Profile</h2>
    <div class="user-card-s2 my-4">
        <div class="avatar">
            <label class="avatar-item" for="fileInput">
                <img [src]="userProfilePic || 'assets/images/user.png'" matTooltip="Click to change the profile photo">
                <div class="background"></div>
            </label>
            <br />
            <input id="fileInput" type='file' (change)="uploadFile($event)" #fileInput>
            <br/>
        </div>
            <div id="error-message" style="color: red; visibility: hidden;font-size: medium;
            padding-top: 20px;">
                {{ errMessage }}</div>
        
        <div class="user-info pt-2">
            <h2>{{firstName}} {{lastName}}</h2>
        </div>
    </div> 
    <div class="user-card-s2 my-3">
        <div class="avatar">
            <form [formGroup]="profileFormGroup">
    
                <div fxLayout="row" fxLayoutGap="15px">
                    <div fxFlex fxLayoutAlign="start center">
                        <span class="font-18">Email</span>
                    </div>
                    <div fxFlex fxLayoutAlign="start center">
                        <span class="font-18 position-right"><b> {{email}} </b></span>
                    </div>
                </div>
                <div class="mt-4">
                    <div fxLayout="row" fxLayoutGap="15px">
                        <div class="mt-2">
                            <span class="font-18">First Name</span>
                        </div>
                        <div>
                            <input type="text" class="form-control" #myInput  formControlName="sFirstName">
                        </div>
                    </div><br>
                    <mat-error  *ngIf="displayMessage?.sFirstName">
                        {{displayMessage?.sFirstName}}
                    </mat-error>
                </div>
                <div class="">
                    <div fxLayout="row" fxLayoutGap="15px">
                        <div class="mt-2">
                            <span class="font-18">Last Name</span>
                        </div>
                        <div>
                            <input type="text" class="form-control" formControlName="sLastName">
                        </div>
                    </div><br>
                    <mat-error *ngIf="displayMessage?.sLastName">
                        {{displayMessage?.sLastName}}
                    </mat-error>
                </div>
    
            </form>
        </div>
    </div>
    <div fxLayout="row" class="mt-2 p-2" >
        <button [disabled]="!(profileFormGroup.valid && (profileFormGroup.dirty || profilePicUpdated)) || disableSaveBtn" mat-raised-button color="primary" (click)="saveProfile()"  class="w-100 ml-2 mx-2">
            Submit
        </button>
    </div>
    <div fxLayout="row" class="mt-2 p-2" >
        <button mat-raised-button color="secondary" (click)="logout()" id="logout" class="w-100 ml-2 mx-2">
            Logout
        </button>
    </div>
</section>
