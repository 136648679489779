import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }
  private botList = new BehaviorSubject<string>("");
  addBotList = this.botList.asObservable();
  setjourneyColor(addBotList: string) {  
      this.botList.next(addBotList);
  }

  
  private onToggleItem = new BehaviorSubject<any>('');
  $currentToggleItem = this.onToggleItem.asObservable();

  setToggleItem(data:string){
    this.onToggleItem.next(data);
  }


  private projectSource = new BehaviorSubject<any>('');
  private industrySource = new BehaviorSubject<any>('');
  private businessSource = new BehaviorSubject<any>('');
  private filterShare = new BehaviorSubject<any>('');

  currentProject = this.projectSource.asObservable();
  currentIndustry = this.industrySource.asObservable();
  currentBusiness = this.businessSource.asObservable();
  filterShared = this.filterShare.asObservable();
  changeProject(project: any) {
    this.projectSource.next(project);
  }

  changeIndustry(industry: any) {
    this.industrySource.next(industry);
  }

  changeBusiness(business: any) {
    this.businessSource.next(business);
  }

  filterShareDashboard(business: any) {
    this.filterShare.next(business);
  }


}
