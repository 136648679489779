<div class="login"> 
<div class="logo">
  <img src="assets/images/InnoBoon-Logo.png" alt="Logo">
</div>
<div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100" class="auth-container">
  <div class="login-form" fxFlex="60"  fxFlex.xs="100">
    <mat-card>
     <span class="l-header">Login</span>
      <form [formGroup]="loginForm">
        <div class="login-group">
          <div fxLayout="column" class="pt-3">
              <div fxLayout="row" fxLayout.xs="column">
                  <mat-label class="h-t"> Email</mat-label>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                  <mat-form-field appearance="outline" class="example-full-width">
                      <input #mobileno matInput id="emailId"  name="emailId" required formControlName="sEmail" 
                          placeholder="Enter Your Email" autocomplete="off" />
                  </mat-form-field>
              </div>
              <mat-error *ngIf="displayMessage.sEmail">
                  {{displayMessage.sEmail}}
              </mat-error>
          </div>
        </div>
        <div class="login-group">
          <div fxLayout.xs="column" fxLayout="column" fxLayout.xs="column" class="ip-field" >
              <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="25px" fxLayoutGap.xs="0px">
                  <mat-label class="label-font asterisk h-t" for="sPwd">Password </mat-label>
              </div>
              <div fxLayout.xs="column" fxLayout="row">
                  <mat-form-field appearance="outline" class="example-full-width">
                      <input matInput  id="sPwd" formControlName="sPwd"  [type]="hide ? 'password' : 'text'"
                          name="sPwd" required placeholder="Enter Password" autocomplete="off" />
                          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon class="eye-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </button>
                  </mat-form-field>
              </div>
              <mat-error *ngIf="displayMessage.sPwd">
                  {{displayMessage.sPwd}}
              </mat-error>
          </div>
          <div fxLayout="row" class="p-1 pt-2" fxLayoutAlign="end center">
              <a routerLink="/reset-password" class="h-t" >Forgot your Password? </a>
           </div>
        </div>
      </form>
        <div class="login-group">
         <div fxLayout="row" class="p-1 mt-3" fxLayoutAlign="center center">
              <div  class="login-button" fxLayoutAlign="center center">
                  <button mat-raised-button [disabled]="!loginForm.valid" class="login-button" (click)="onSubmit()" color="primary">
                          Log in
                  </button>
              </div>
          </div>
          <div *ngIf="this.getLoading()" class="loader-container">
              <div class="lds-dual-ring"></div>
            </div>
        </div>
    </mat-card>
  </div>
</div>
</div>