import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import {
  FormControlName,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, first } from 'rxjs/operators';
import { Observable, fromEvent, merge } from 'rxjs';
import { Login } from 'src/app/core/config/validation.config';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { GenericValidator } from 'src/app/core/utils/directive/generic-validator';
import { IUser, UserService } from 'src/app/core/services/user/user.service';
import { environment } from 'src/environment/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Login component
 */
export class LoginComponent implements OnInit {
  loginForm!: UntypedFormGroup;
  submitted = false;
  error = '';
  returnUrl: string = '';
  @ViewChildren(FormControlName, { read: ElementRef }) formControls!: ElementRef[];
  // Use with the generic validation message class
  private validationMessages: { [key: string]: { [key: string]: string } };
  displayMessage: { [key: string]: string } = {};
  private genericValidator: GenericValidator;

  // set the currenr year
  year: number = new Date().getFullYear();
  loading: boolean = false;
  sessionExpired: any = false;
  user_role: any;
  currentUser: any
  hide:boolean = true;
  datauser: { email: any; first_name: any; last_name: any; user_id: any; user_type: any; };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private uservice: UserService,
    public authService: AuthService,
  ) {
    this.sessionExpired = this.authService.getSessionExpired();
    this.validationMessages = Login
    this.currentUser = window.sessionStorage.getItem('currentUser');
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      sEmail: ['', Validators.required],
      sPwd: ['', Validators.required],
    });

  }
  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formControls
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(this.loginForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(600)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.loginForm);
    });
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    } else {
      // this.loading = false;
      // this.router.navigate(['features/dashboard'])

      this.authService.login(this.loginForm.controls['sEmail'].value, this.loginForm.controls['sPwd'].value).subscribe(data => {
           this.loading = false;
            let user = data;
            if (user && user.user_details) {
              sessionStorage.setItem( 'currentUser', JSON.stringify(user.user_details));
              this.authService.currentUserSub(user.user_details);
              const currentUser:any = window.sessionStorage.getItem('currentUser');
              this.datauser = {
                email: JSON.parse(currentUser).email,
                first_name: user.user_details.first_name,
                last_name: user.user_details.last_name,
                user_id: user.user_details.user_id,
                user_type: JSON.parse(currentUser).user_type
              }
              // let obj=<IUser>{};
              // obj.currentUser = JSON.stringify(this.datauser)
              
              // this._uService.setmode(obj);
              // this.router.navigate(['features/dashboard'])
              this.authService.getProfile("/auth/get_user_profile","").subscribe(data => {
                let obj=<IUser>{};
                obj.currentUser = JSON.stringify(this.datauser),
                obj.userProfilePic = data.user_details.profile_pic ? data.user_details.profile_pic : '';
                var curdata: any = window.sessionStorage.getItem('currentUser');
                curdata = curdata ? JSON.parse(curdata) : {};
                curdata['profile_pic'] = (obj.userProfilePic);
                sessionStorage.setItem('currentUser', JSON.stringify(curdata));
                this.uservice.setmode(obj);
                this.router.navigate(['features/dashboard'])
              })
            }
          },
          error => {
            if(error=="Invalid email"){
              this.loginForm.controls['sEmail'].setErrors({ valid: true });
              this.displayMessage = this.genericValidator.processMessages(this.loginForm);
            }  if(error=="Invalid password"){
              this.loginForm.controls['sPwd'].setErrors({ valid: true });
              this.displayMessage = this.genericValidator.processMessages(this.loginForm);
            }
           
            this.loading = false;
            this.error = error ? error : '';
          }
        );
    }
  }


  setLoading(loading: boolean) {
    this.loading = loading;
  }

  getLoading(): boolean {
    return this.loading;
  } 
}
